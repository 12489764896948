import React, { useState, useRef } from 'react';
import { Container, Typography, TextField, Button, Grid, Snackbar, Alert, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import Navbar from '../pages/theme/Navbar';
import Footer from './Footer';
import confederando from '../images/Confederando.png';
import api from '../service/Api';
import PesquisaSatisfacao from './PesquisaSatisfacao';
import Certificado from './Certificado';
import { useReactToPrint } from 'react-to-print';

const IndexPosEvento = () => {
    const [cpf, setCpf] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [showPesquisa, setShowPesquisa] = useState(false);
    const [showIndex, setShowIndex] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const certificadoRef = useRef();
    const [certificadoData, setCertificadoData] = useState(null);
    const [user, setUser] = useState('');

    const Print = useReactToPrint({
        content: () => certificadoRef.current,
    });

    const showAlert = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const FormatCPF = (value) => {
        if (value) {
            value = value.replace(/\D/g, '');
            value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
            return value;
        }
        return '';
    };

    const apiCall = async () => {
        try {
            const userCpf = cpf.replace(/\D/g, '');
            if (userCpf.length < 11) {
                showAlert('CPF digitado é inválido', 'error');
                return;
            }
            const response = await api.get(`api/getCertificado/${userCpf}`);
            const { data } = response;
            setUser(data);

            if (data.Certificado === null) {
                showAlert('Você não está apto para imprimir o certificado.', 'error');
            } else {
                if (data.Avaliacao === null) {
                    setShowPesquisa(true);
                    setShowIndex(false);
                } else {
                    if (data?.Recibo !== 'Pagamento não localizado!') {
                        showAlert('', 'success');
                    }
                    setCertificadoData(data);
                    setTimeout(() => {
                        Print();
                    }, 200);
                }
            }
        } catch (error) {
            console.error('Erro ao obter dados do usuário: ', error);
            showAlert('Erro ao obter dados do usuário.', 'error');
        }
    };

    return (
        <>
            {showIndex && (
                <Box display="flex" flexDirection="column" minHeight="100vh">
                    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                    <Navbar />
                    <Container component="main" maxWidth="lg" sx={{ mt: 8, mb: 2, flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        {!isMobile && (
                            <Box component="img" src={confederando} alt="Confederando" sx={{ maxHeight: '80vh', marginRight: 4 }} />
                        )}
                        <Box flexGrow={1}>
                            <Typography variant="h3" gutterBottom align="center">
                                Agradecemos a sua participação!
                            </Typography>
                            <Typography variant="h5" gutterBottom align="center" color="textSecondary">
                                A XXV edição da Marcha a Brasília em Defesa dos Municípios demonstrou novamente a força do movimento municipalista e a unidade dos agentes políticos locais em prol dos Municípios brasileiros.
                            </Typography>
                            <Typography variant="h6" gutterBottom align="center" color="textSecondary">
                                Retire seu certificado informando seu CPF no campo indicado abaixo.
                            </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={6}>
                                    <TextField
                                        label="Digite o CPF"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ maxLength: 14 }}
                                        value={FormatCPF(cpf)}
                                        onChange={(e) => setCpf(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                apiCall();
                                            }
                                        }}
                                    />
                                    <Typography variant="body2" color="textSecondary" align="center">
                                        *Se houver registro de pagamento, o recibo será enviado para o e-mail de cadastro.
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={apiCall}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Enviar
                                    </Button>
                                </Grid>
                                {/* {showPrintButton && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={Print}
                                    >
                                        Imprimir Certificado
                                    </Button>
                                )} */}
                            </Grid>
                        </Box>
                    </Container>

                    <Box mt="auto">
                        <Footer />
                    </Box>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                        >
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                    <div style={{ display: 'none' }}>
                        {certificadoData && (
                            <Certificado
                                ref={certificadoRef}
                                nome={certificadoData.Certificado.nome}
                                cargo={certificadoData.Certificado.cargo}
                                municipio={certificadoData.Certificado.municipio}
                                uf={certificadoData.Certificado.uf}
                            />
                        )}
                    </div>
                </Box>
            )}
            {showPesquisa && <PesquisaSatisfacao user={user.Certificado} />}
        </>
    );
};

export default IndexPosEvento;
